import { EnvironmentVariable } from '../../../utils/constants';

import { UserEngagementData } from './organization.types';

export const getPreEmploymentData = async (organization: string, limit: number) => {
  const preEmploymentUrl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_PRE_EMPLOYMENT_URL];

  const response = await fetch(`${preEmploymentUrl}?organization=${organization}&limit=${limit}`);
  const result = await response?.json();
  return result.usersData; // {size:number,employeeData:[]}
};

export const getUserEngagementData = async (organization: string, limit: number) => {
  const getUserEngagementurl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_USER_ENGAGEMENT_URL];

  const response = await fetch(
    `${getUserEngagementurl}?organization=${organization}&limit=${limit}`,
  );
  const result = await response.json();
  return result.usersData; // {size:number,employeeData:[]}
};

export const getUserCourseData = async (organization: string, limit: number) => {
  const getUserCourseUrl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_USER_COURSE_DATA_URL];

  const response = await fetch(`${getUserCourseUrl}?organization=${organization}&limit=${limit}`);
  const result = await response.json();
  return result.usersData; // {size:number,employeeData:[]}
};

export const getOrganizationUserStastics = async (organization: string, limit: number) => {
  const getOrganizationUserStasticsUrl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_ALL_USER_STASTIC_URL];

  const response = await fetch(
    `${getOrganizationUserStasticsUrl}?organization=${organization}&limit=${limit}`,
  );
  const result = await response.json();
  return result?.usersData; // {totalUsersCount:number,completedPreEmploymentCount:number,pending:number}
};

export const dispatchReminderEmail = async (user: UserEngagementData) => {
  const reminderUrl = process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_REMINDER_EMAIL_URL];
  if (reminderUrl) {
    const response = await fetch(reminderUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ to: user?.email, userName: user?.name || user?.email }),
    });
    return response;
  }
  return;
};

// Report APi----------------------------------------------------------------------------------->

const formatDate = (date: Date) => date.toISOString().split('T')[0];
const nodeEnvironment = process.env[EnvironmentVariable.REACT_APP_NODE_ENV]; // stage or Prod
export const getOrganizaationAllUserSwotReport = async (
  organization: string,
  startDate?: string,
  endDate?: string,
) => {
  const defaultFromDate = new Date('2023-10-01T12:31:10+05:30');
  const defaultToDate = new Date();
  if (!startDate) {
    startDate = formatDate(defaultFromDate);
  }

  if (!endDate) {
    endDate = formatDate(defaultToDate);
  }
  const organizaationAllUserSwotReportUrl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_USER_SWOT_REPORT_URL];

  const response =
    organizaationAllUserSwotReportUrl &&
    (await fetch(organizaationAllUserSwotReportUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organization: organization,
        startDate: startDate,
        endDate: endDate,
        env: nodeEnvironment,
      }),
    }));
  const result = response && (await response.json());
  return result.result; // {swotData:{},totalUsers:number}
};

export const getSwotReportAsPerRegionAndAge = async (
  organization: string,
  startDate?: string,
  endDate?: string,
) => {
  const defaultFromDate = new Date('2023-10-01T12:31:10+05:30');
  const defaultToDate = new Date();
  if (!startDate) {
    startDate = formatDate(defaultFromDate);
  }

  if (!endDate) {
    endDate = formatDate(defaultToDate);
  }
  const SwotReportAsPerRegionAndAgeUrl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_USER_SWOT_REPORT_REGION_AGE_URL];

  const response =
    SwotReportAsPerRegionAndAgeUrl &&
    (await fetch(SwotReportAsPerRegionAndAgeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organization: organization,
        startDate: startDate,
        endDate: endDate,
        env: nodeEnvironment,
      }),
    }));
  // return response;
  const result = response && (await response.json());
  return result.result; // {swotData:{},totalUsers:number}
};

export const getPreAssessmentReportByRegion = async (
  organization: string,
  startDate?: string,
  endDate?: string,
) => {
  const defaultFromDate = new Date('2023-10-01T12:31:10+05:30');
  const defaultToDate = new Date();
  if (!startDate) {
    startDate = formatDate(defaultFromDate);
  }

  if (!endDate) {
    endDate = formatDate(defaultToDate);
  }
  const PreAssessmentReportByRegionUrl =
    process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_USER_PREASSESSMENT_REPORT_REGION_URL];

  const response =
    PreAssessmentReportByRegionUrl &&
    (await fetch(PreAssessmentReportByRegionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organization: organization,
        startDate: startDate,
        endDate: endDate,
        env: nodeEnvironment,
      }),
    }));
  const result = response && (await response.json());
  return result.result; // {swotData:{},totalUsers:number}
};
